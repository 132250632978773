import React from 'react';
import logo from './newlogo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="American Wheel Repair logo" className="App-logo"/>
        <h1>American Wheel Repair</h1>
        <p>Expert Wheel and Rim Restoration in Northern Virginia</p>
      </header>
      <nav className="App-nav">
        <a href="#mission">Mission</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a>
      </nav>
      <main>
        <section id="mission" className="App-section">
          <h2>Company Mission</h2>
          <p>Welcome to American Wheel Repair, where we restore your wheels and rims to their former glory. We're committed to providing expert craftsmanship and outstanding customer service to get you back on the road safely and in style.</p>
        </section>
        <section id="about" className="App-section">
          <h2>About Company</h2>
          <p>Founded in 20XX and proudly serving Northern Virginia, American Wheel Repair is your trusted source for wheel and rim repair. We specialize in fixing curb damage, scratches, bends, and more, saving you money compared to costly replacements.</p>
          <h3>About Team</h3>
          <p>Owned and operated by Christian Witcher, an experienced wheel repair technician, American Wheel Repair offers a personalized approach. We're passionate about cars and restoring wheels to like-new condition.</p>
        </section>
        <section id="services" className="App-section">
          <h2>Services</h2>
          <ul>
            <li>Curb Rash Repair: Eliminate those unsightly scrapes and scuffs.</li>
            <li>Bent Wheel Straightening: Restore structural integrity for a smooth ride.</li>
            <li>Custom Refinishing: Match your factory color or choose a new style.</li>
          </ul>
        </section>
        <section id="contact" className="App-section">
          <h2>Contact Info</h2>
          <p>Christian Witcher<br/>
          Owner & Wheel Repair Specialist<br/>
          Email: americanwheelrepairllc@gmail.com<br/>
          Phone: ‭(703) 623-7583‬</p>
        </section>
      </main>
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} American Wheel Repair. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
